<!-- Order details -->
<div>
  <!-- Order infos -->
  <div>
    @if (order.scheduledDate) {
      <div class="order-field">
        <div class="field-name">{{ 'common.date' | translate }}</div>
        <div>
          <i class="las la-calendar-day"></i>
          <span>{{ order.scheduledDate | date: 'fullDate' : '' : translateService.currentLang }}</span>
        </div>
      </div>
    }

    @if (linkType !== LinkType.TRACK && linkType !== LinkType.REVIEW) {
      <div class="order-field">
        <div class="field-name">{{ 'common.timeSlot' | translate }}</div>
        <div>
          <i class="las la-clock"></i>
          <span>{{ order.timeSlot | timeSlot }}</span>
        </div>
      </div>
    }

    @if (
      (linkType === LinkType.TRACK && (order.arrivalTimeLive || order.arrivalTimeTheoretical)) ||
      (linkType === LinkType.REVIEW && order.doneTime)
    ) {
      <div class="order-field">
        <div class="field-name">
          @if (linkType === LinkType.TRACK) {
            {{ 'common.arrivalTime' | translate }}
          }
          @if (linkType === LinkType.REVIEW) {
            {{ 'common.terminatedDate' | translate }}
          }
        </div>
        <div>
          <i class="las la-clock"></i>
          @if (linkType === LinkType.TRACK) {
            <span>{{ (order.arrivalTimeLive ? order.arrivalTimeLive : order.arrivalTimeTheoretical) | slice: 0 : 5 }}</span>
          }
          @if (linkType === LinkType.REVIEW) {
            <span>{{ order.doneTime | slice: 0 : 5 }}</span>
          }
        </div>
      </div>
    }

    @if (settingsService.link.showOrderDuration) {
      <div class="order-field">
        <div class="field-name">{{ 'common.duration' | translate }}</div>
        <div>
          <i class="las la-hourglass-start"></i>
          <span>{{ order.duration }} {{ 'common.minutes' | translate }}</span>
        </div>
      </div>
    }

    @if (settingsService.link.showOrderPrice && settingsService.link.order.price > 0) {
      <div class="order-field">
        <div class="field-name">{{ 'common.price' | translate }}</div>
        <div>
          <span
            >{{ settingsService.currency }} {{ order.price | number: '1.2-2' : settingsService.language.fullId }}
            {{ settingsService.link.currencyCode }}</span
          >
        </div>
      </div>
    }

    <div class="order-field">
      <div class="field-name">{{ 'common.address' | translate }}</div>
      @if (!isEditMode || !settingsService.link.allowCustomerToEditAddress || order.orderLocationType === OrderLocationType.CUSTOM) {
        <div class="ellipsis">
          <i class="las la-map-marker-alt"></i>
          <span matTooltip="{{ order.location.address }}" matTooltipClass="full-tooltip" matTooltipPosition="above">{{
            order.location.address
          }}</span>
        </div>
      }
      @if (isEditMode && settingsService.link.allowCustomerToEditAddress && order.orderLocationType !== OrderLocationType.CUSTOM) {
        <app-location-finder [location]="location" (locationChange)="locationChange($event)" [form]="form"></app-location-finder>
      }
    </div>

    @if (order.customerPhoneNumber || (isEditMode && settingsService.link.allowCustomerToEditPhoneNumber)) {
      <div class="order-field">
        <div class="field-name">{{ 'common.phoneNumber' | translate }}</div>
        @if (!isEditMode || !settingsService.link.allowCustomerToEditPhoneNumber) {
          <div class="ellipsis">
            <i class="las la-phone"></i>
            <span>{{ order.customerPhoneNumber }}</span>
          </div>
        }
        @if (isEditMode && settingsService.link.allowCustomerToEditPhoneNumber) {
          <app-phone-input [form]="form" [number]="userPhone" (numberChange)="numberChange($event)"></app-phone-input>
        }
      </div>
    }

    @if (linkType !== LinkType.SCHEDULE && settingsService.link.addAgentContact && order.agentLabel) {
      <div class="order-field">
        <div class="field-name">{{ 'common.agent' | translate | terminology | titlecase }}</div>
        @if (!!order.agentLabel) {
          <div class="agent-name">
            <span class="agent-icon">{{ getInitials(order.agentLabel) }}</span>
            <span class="ellipsis" matTooltip="{{ order.agentLabel }}" matTooltipClass="full-tooltip" matTooltipPosition="above">
              {{ order.agentLabel }}
            </span>
          </div>
        }
        <div class="ellipsis">
          @if (!!order.agentPhoneNumber) {
            <i class="las la-phone"></i>
            <span class="right-padding-15">{{ order.agentPhoneNumber }}</span>
          }
          <i class="las la-truck"></i>
          <span>{{ order.registrationNumber }}</span>
        </div>
      </div>
    }
  </div>
</div>

<!-- Custom fields -->
@if (visibleFields.length > 0) {
  <div>
    @for (field of visibleFields; track field) {
      <div class="order-field">
        <div class="field-name ellipsis">{{ field.field.name }}</div>
        <app-edit-custom-field
          [value]="field.value"
          [name]="field.field.id"
          [fieldType]="field.field.type"
          [field]="field.field"
          [form]="form"
          [isEditable]="false"
          (fieldChange)="onFieldChange($event)"
        ></app-edit-custom-field>
      </div>
    }
  </div>
}
@if (order.editableFields?.length > 0) {
  <div>
    @for (field of order.editableFields; track field) {
      <div class="order-field">
        <div class="field-name ellipsis">{{ field.name }}</div>
        <app-edit-custom-field
          [name]="field.id"
          [fieldType]="field.type"
          [field]="field"
          [form]="form"
          [isEditable]="true"
          [isEditing]="isEditMode"
          (fieldChange)="onFieldChange($event)"
        ></app-edit-custom-field>
      </div>
    }
  </div>
}

<!-- Order reports -->
@if (
  orderReports.length > 0 && ((linkType === LinkType.REVIEW && state === State.DONE) || (linkType === LinkType.TRACK && isRouteStarted))
) {
  <h4>{{ 'common.reports' | translate }}</h4>
  @for (report of orderReports; track report) {
    <div>
      <div class="order-report">
        <span class="ellipsis">{{ report.reportName }}</span>
        <button mat-icon-button (click)="downloadReport(report.reportId)">
          <i class="las la-download"></i>
        </button>
      </div>
    </div>
  }
}
