import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Observable, Subject } from 'rxjs';

import { BaseLinkSettings, CurrencyService, LinkType, TerminologyService } from '@antsway/antsroute-common';
import { en, Language, languages } from '../models/language.model';
import { CustomerLink, CustomerLinkOrder } from '../models/settings.model';
import { hexToRgba } from '../utils/color';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService<T extends BaseLinkSettings> {
  link: CustomerLink<T>;
  currency: string;

  token: string;

  language: Language;
  private refreshMap = new Subject<boolean>();
  $refreshMap = this.refreshMap.asObservable();

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private httpClient: HttpClient,
    private terminologyService: TerminologyService,
    private alertService: AlertService,
    private currencyService: CurrencyService
  ) {}

  getLink(token: string, type: LinkType): Promise<CustomerLink<T>> {
    return new Promise((resolve) => {
      this.initSettings(token, type).subscribe({
        next: (data) => {
          this.link = data;
          this.token = token;

          this.changeLanguage(navigator.language);
          this.currency = this.currencyService.getCurrency(this.link.currencyCode).symbol;

          if (this.link.terminology) {
            this.terminologyService.terminology = this.link.terminology;
          }

          // Apply global theme
          document.documentElement.style.setProperty('--theme-color-1', this.link.color);
          document.documentElement.style.setProperty('--theme-color-calendar-hover', hexToRgba(this.link.color, 0.3));
          document.documentElement.style.setProperty('--theme-color-2', hexToRgba(this.link.color, 0.15));

          resolve(this.link);
        },
        error: (error) => {
          this.showAlert(error);
        }
      });
    });
  }

  updateCustomerInfos(token: string, config: CustomerLink<T>): Promise<void> {
    return new Promise((resolve) => {
      this.updateInfos(token, config.order, config.linkSettings.type).subscribe({
        next: async () => {
          this.link = await firstValueFrom(this.initSettings(token, config.linkSettings.type));
          this.refreshMap.next(true);
          resolve();
        },
        error: (error) => {
          this.showUpdateAlert(error);
        }
      });
    });
  }

  private initSettings(token: string, type: LinkType): Observable<CustomerLink<T>> {
    return this.httpClient.get<CustomerLink<T>>('/api/customer-links/settings', {
      headers: new HttpHeaders({ 'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0' }),
      params: new HttpParams().set('token', token).set('type', type)
    });
  }

  private updateInfos(token: string, order: CustomerLinkOrder, linkType: LinkType): Observable<void> {
    const params = new HttpParams().set('token', token).set('linkType', linkType);
    return this.httpClient.put<void>('/api/customer-links/update-infos', order, { params });
  }

  changeLanguage(language: string): void {
    const lang = language.substring(0, 2);
    this.language = languages.find((value) => value.id === lang);
    if (!this.language) {
      this.language = en;
    }
    this.translateService.use(this.language.id);
  }

  showAlert(error: any): void {
    let errorKey;
    if (error.status === 409 && error.error.errorCode === 1556) {
      errorKey = 'error.linkError';
    } else if (error.status === 403) {
      errorKey = 'error.unknownLink';
    } else {
      errorKey = 'error.genericError';
    }
    this.router.navigateByUrl('/error', {
      state: {
        error: errorKey
      }
    });
  }

  showUpdateAlert(error: any): void {
    if (error.status === 409) {
      const errorCode = error.error.errorCode;
      if (errorCode === 51) {
        this.alertService.alert('error.customer.unsupportedLocation');
      } else if (errorCode === 105) {
        this.alertService.alert('error.customer.geocodingFailed');
      } else {
        this.alertService.alert('error.conflict');
      }
    } else {
      this.alertService.alert('error.conflict');
    }
  }
}
