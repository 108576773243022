import { AlertService } from '../../services/alert.service';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseLinkSettings, CustomFieldValue, LinkType, OrderLocationType, State, TerminologyPipe } from '@antsway/antsroute-common';
import { TranslateService } from '@ngx-translate/core';
import { FileSaverService } from 'ngx-filesaver';
import { Subscription } from 'rxjs';
import { Location } from '../../models/location.model';
import { CustomerLink, CustomerLinkOrder, CustomerLinkReport } from '../../models/settings.model';
import { SettingsService } from '../../services/settings.service';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-order-infos',
  templateUrl: './order-infos.component.html',
  styleUrls: ['./order-infos.component.scss']
})
export class OrderInfosComponent<T extends BaseLinkSettings> implements OnChanges, OnDestroy {
  @Input() isEditMode: boolean = false;
  @Input() config: CustomerLink<T>;
  @Input() token: string;

  @Output() isFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() configChange: EventEmitter<CustomerLink<T>> = new EventEmitter<CustomerLink<T>>();

  State = State;
  OrderLocationType = OrderLocationType;

  order: CustomerLinkOrder;
  visibleFields: CustomFieldValue[] = [];
  state: State;
  linkType: LinkType;
  LinkType = LinkType;

  private language: string;
  private languageSubscription: Subscription;

  private commonCustomerText: string;
  private commonAgentText: string;

  form: FormGroup;
  location: Location = new Location();
  userPhone: string;
  orderReports: CustomerLinkReport[] = [];
  private loadingReport: boolean;
  isRouteStarted: boolean;

  constructor(
    private formBuilder: FormBuilder,
    public settingsService: SettingsService<BaseLinkSettings>,
    public translateService: TranslateService,
    private terminologyService: TerminologyPipe,
    private downloadReportService: ReportService,
    private fileSaverService: FileSaverService,
    private alertService: AlertService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config) {
      this.order = this.config.order;
      this.updateVisibleFields();
      this.state = this.config.order.state;
      this.linkType = this.config.linkSettings.type;
      this.orderReports = this.config.order.reports;
      this.isRouteStarted = !!this.config.order.routeStartedAt;
    }
    this.form = this.formBuilder.group({
      address: this.formBuilder.control(this.config.order.location.address.slice(), [Validators.required]),
      phone: this.formBuilder.control(this.config.order.customerPhoneNumber?.slice())
    });

    for (const editableField of this.order.editableFields) {
      const fieldInOrder = this.order.fields.find((field) => field.field.id === editableField.id);
      this.form.addControl(String(editableField.id), this.formBuilder.control(fieldInOrder ? fieldInOrder.value : null));
    }
    this.isFormValid.emit(this.form.valid);

    this.translateService.onLangChange.subscribe((value) => {
      this.setLanguage(value.lang);
    });
    this.setLanguage(this.settingsService.language.id);

    this.form.valueChanges.subscribe((): void => {
      this.isFormValid.emit(this.form.valid);
      if (this.form.valid) this.configChange.emit(this.config);
    });
  }

  ngOnDestroy(): void {
    this.languageSubscription?.unsubscribe();
  }

  private updateVisibleFields(): void {
    const editableFieldsIds = this.order.editableFields.map((field) => field.id);
    this.visibleFields = this.order.fields.filter((field) => !editableFieldsIds.includes(field.field.id));
  }

  onFieldChange(fieldEvent: [number, any]): void {
    const existingField = this.config.order.fields.find((f) => f.field.id === fieldEvent[0]);
    if (existingField) {
      if (fieldEvent[1]) {
        existingField.value = fieldEvent[1];
      } else {
        this.config.order.fields.splice(this.config.order.fields.indexOf(existingField), 1);
      }
    } else if (fieldEvent[1]) {
      // Create the new field from the value, and infos from editable fields array
      const newField = new CustomFieldValue();
      const fieldInEditableFields = this.config.order.editableFields.find((f) => f.id === fieldEvent[0]);
      if (fieldInEditableFields) {
        newField.field = fieldInEditableFields;
        newField.value = fieldEvent[1];
        newField.stringValue = String(newField.value);
        newField.type = newField.field.type;
        this.config.order.fields.push(newField);
      }
    }
    this.isFormValid.emit(this.form.valid);
    if (this.form.valid) this.configChange.emit(this.config);
  }

  locationChange(event: Location): void {
    this.config.order.location = event;
  }

  numberChange(event: string): void {
    this.config.order.customerPhoneNumber = event;
  }

  private setLanguage(language: string): void {
    this.language = language;
    this.commonCustomerText = this.terminologyService.transform(this.translateService.instant('common.customer'));
    this.commonAgentText = this.terminologyService.transform(this.translateService.instant('common.agent'));
  }

  getInitials(agentName: string): string {
    const names = agentName.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  }

  downloadReport(reportId: number): void {
    this.loadingReport = true;
    this.downloadReportService.getOrderReport(this.token, this.linkType, reportId, this.language).subscribe({
      next: (response) => {
        const file = new Blob([response.body]);
        this.fileSaverService.save(file, response.headers.get('filename'));
        this.loadingReport = false;
      },
      error: (error) => {
        if (error.status === 409) {
          const decodedString = String.fromCharCode.apply(null, new Uint8Array(error.error));
          const decodedError = JSON.parse(decodedString);
          console.log(decodedError);
          const errorCode = decodedError.errorCode;
          if (errorCode === 1468) {
            this.alertService.alert('error.report.invalidLinkType');
          } else if (errorCode === 1469) {
            this.alertService.alert('error.report.invalidReportId');
          } else {
            this.alertService.alert('error.conflict');
          }
        } else {
          this.alertService.alert('error.conflict');
        }
        this.loadingReport = false;
      }
    });
  }
}
