@if (config) {
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutAlign.xs="center start"
    style="height: 100%"
    [ngStyle.gt-xs]="{ padding: '30px 0px' }"
  >
    <mat-card appearance="outlined" fxLayout="column" fxFlex.gt-xs="400px" fxFlex.xs="grow" ngClass.xs="xs">
      <app-header></app-header>
      <mat-card-content fxFlex="grow" fxLayout="column">
        <div class="page-description">
          <h3>
            @if (LinkType.SCHEDULE === linkType) {
              <span>{{ 'schedule.orderProgramming' | translate | terminology }}</span>
            }
            @if (LinkType.CONFIRM_RESCHEDULE === linkType) {
              <span>{{ 'confirm.orderReprogramming' | translate | terminology }}</span>
            }
          </h3>
          @if (step === 'ERROR' && config.linkSettings.messageError) {
            <p class="error">
              {{ config.linkSettings.messageError }}
            </p>
          }
        </div>
        <mat-calendar
          [dateFilter]="dateFilter"
          [(selected)]="date"
          [minDate]="minDate"
          (selectedChange)="selectDate($event)"
        ></mat-calendar>
        <div fxLayout="column">
          <!-- Time slots -->
          @if (scoreInProgress) {
            <mat-spinner fxFlexAlign="center"></mat-spinner>
          }
          @if (scoreOrderReport) {
            <h3>{{ date | date: 'fullDate' : '' : translateService.currentLang }}</h3>
            @if (scoreOrderReport.status.length > 0) {
              <div class="timeslots">
                @for (status of scoreOrderReport.status; track status; let i = $index) {
                  <button
                    mat-button
                    class="timeslot"
                    [class.timeslot-selected]="
                      (status.timeSlot && status.timeSlot === selectedStatus?.timeSlot) || (!status.timeSlot && selectedStatus)
                    "
                    (click)="selectTimeSlot(status)"
                  >
                    @if (!status.timeSlot || status.timeSlot.id === 0) {
                      {{ 'confirm.allDay' | translate }}
                    }
                    @if (status.timeSlot && status.timeSlot.id !== 0) {
                      <div>
                        <span> {{ status.timeSlot.from | slice: 0 : 5 }} - {{ status.timeSlot.to | slice: 0 : 5 }} </span>
                      </div>
                    }
                  </button>
                }
                <div class="timer">
                  <span class="fake-icon">!</span>
                  <span>
                    <span>
                      {{ 'confirm.availabilitiesExpired' | translate }}
                    </span>
                    <b>
                      <countdown [config]="countdownConfig" (event)="handleCountDownEvent($event)"></countdown>
                    </b>
                  </span>
                </div>
              </div>
            }
            @if (scoreOrderReport.status.length === 0) {
              <p>
                {{ 'confirm.noAvailability' | translate }}
              </p>
            }
          }
        </div>
      </mat-card-content>
      <mat-card-actions class="footer">
        <div class="actions">
          <div class="choices">
            <button mat-flat-button class="back" (click)="cancel()">
              {{ 'common.back' | translate }}
            </button>
            <button mat-flat-button class="button1" (click)="validateScore()" [disabled]="!selectedStatus || validating">
              {{ 'common.save' | translate }}
              @if (settingsService.link.showOrderPrice && settingsService.link.order.price > 0) {
                | {{ settingsService.link.order.price | currency: settingsService.currency:'symbol-narrow' : '1.2-2': settingsService.language.fullId}}
              }
            </button>
          </div>
        </div>
        <ar-watermark></ar-watermark>
      </mat-card-actions>
    </mat-card>
  </div>
}
